<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Map default position">
                <div id="map" style="width: 100%; height: 500px"></div>
                <hr/>
                <b-button variant="primary" @click="saveMapPosition">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save position</span>
                </b-button>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BCard, BButton, BOverlay} from 'bootstrap-vue'
    import mapboxgl from 'mapbox-gl'
    import 'mapbox-gl/dist/mapbox-gl.css'

    export default {
        components: {
            BCard,
            BOverlay,
            BButton
        },
        data() {
            return {
                dataLoaded: false,
                config: {},
                map: null
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/config/default_map_view')
                loadPromise.then(function(response) {
                    if (response.data.zoom !== undefined && response.data.center !== undefined) {
                        thisIns.config = response.data
                    } else {
                        thisIns.config = {center: [0, 0], zoom: 1}
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                    if (thisIns.map === null) {
                        thisIns.attachMap()
                    }
                })
            },
            saveMapPosition() {
                const thisIns = this
                const loadPromise = this.$http.put('/api/management/v1/config/default_map_view', {
                    center: [this.map.getCenter().lng, this.map.getCenter().lat],
                    zoom: this.map.getZoom()
                })
                loadPromise.then(function() {
                    thisIns.$printSuccess('Map default position saved')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            attachMap() {

                mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: this.config.center,
                    zoom: this.config.zoom
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>